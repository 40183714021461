export enum Module {
  HOTEL = "Hotel",
  FLIGHT = "Flight",
  SUBSCRIPTION = "Subscription",
}

export enum ltPartners {
  ARAJET = "Arajet",
}


export enum LanguagesLocale {
  ENGLISH = "en",
  ESPANOL = "es",
  PORTUGUESE = "pt",
}

