import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CheckOutService {
  constructor() {}

  private traveler = new BehaviorSubject({});
  getTraveler = this.traveler.asObservable();

  private travelers = new BehaviorSubject([]);
  getTravelers = this.travelers.asObservable();

  private travelerFormData = new BehaviorSubject([]);
  getTravelerFormData = this.travelerFormData.asObservable();

  private countires = new BehaviorSubject([]);
  getCountries = this.countires.asObservable();

  private priceSummary = new BehaviorSubject({});
  getPriceSummary = this.priceSummary.asObservable();

  private callApiofListCartFromSingin = new BehaviorSubject({});
  getCallApiofListCartFromSingin =
    this.callApiofListCartFromSingin.asObservable();

  private cartTotalAmount = new BehaviorSubject({});
  getCartTotalAmount = this.cartTotalAmount.asObservable();

  private travelersEditedFormData = new BehaviorSubject({});
  getTravelersEditedFormData = this.travelersEditedFormData.asObservable();

  private memberData = new BehaviorSubject({});
  getMemberData = this.memberData.asObservable();


  selectTraveler(traveler) {
    this.traveler.next(traveler);
  }

  setTravelers(travelers) {
    this.travelers.next(travelers);
  }

  emitTravelersFormData(travelers) {
    this.travelerFormData.next(travelers);
  }

  setCountries(countires) {
    this.countires.next(countires);
  }

  setPriceSummary(priceSummary) {
    this.priceSummary.next(priceSummary);
  }

  setCallApiofListCartFromSignin(data) {
    this.callApiofListCartFromSingin.next(data);
  }

  setTotalAmountOfCart(data) {
    this.cartTotalAmount.next(data);
  }

  setTravelersEditedFormData(travelers) {
    this.travelersEditedFormData.next(travelers);
  }

  setMemberData(memberData) {
    this.memberData.next(memberData);
  }

}
