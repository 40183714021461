import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as jwt_decode from "jwt-decode";
import { GenericService } from 'src/app/shared/services/generic.service';
import { CommonFunction } from 'src/app/shared/_helpers/common-function';

@Component({
  selector: 'app-sso-login',
  templateUrl: './sso-login.component.html'
})
export class SsoLoginComponent implements OnInit {
  token = '';
  code = '/'
  constructor(
    private route: ActivatedRoute,
    public router: Router,
    public commonFunction: CommonFunction,
    public genericService: GenericService
  ) { }

  ngOnInit() {
    this.genericService.getTripfluencer.subscribe((res: any) => {
      if(res && res.tripfluencer){
        this.code = '/'+res.tripfluencer+'/'
      }
    })
    this.route.queryParams.forEach(params => {
      this.token = params.sid;
      this.ssonLogin();
    })

    
  }

  ssonLogin() {
    if (this.token) {
      // let decodedToken = jwt_decode(this.token);
      // if (decodedToken.temp_login) {
      //   sessionStorage.setItem('__temp_login', decodedToken.temp_login)
      // }
      localStorage.setItem("log_status", 'true');
      //var userDetail = getUserDetails(this.token);
      localStorage.setItem("_lay_sess", this.token);
      this.router.navigate([this.code]);
    }
  }


}
